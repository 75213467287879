import Header from './Header.js';
import React, { Component } from 'react';
import makeEndpoint from './Endpoints.js';
import {city} from './config';
import './Stacking.css';

class Stacking extends Component {
    // Initialize the state
    constructor(props) {
        super(props);
        try {
            this.state = {
                userData: JSON.parse(localStorage.getItem('userData')),
                address: this.props.match.params.address,
            };
        } catch {
            this.state = { 
                address: this.props.match.params.address,
            };
        }
    }

    componentDidMount() {
        this.getCurrentBlock();        
        this.getCurrentBTCBlock();        
        this.getStackingStats();
    }

    getCurrentBlock = () => {
        fetch(makeEndpoint('/blocks/current_block'))
            .then(res => res.json())
            .then(currentBlock => {
                this.setState({ currentBlock: currentBlock.currentBlock });
            })
    }

    getCurrentBTCBlock = () => {
        fetch('https://api.blockcypher.com/v1/btc/main')
            .then(function(response) {

                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();               
            }).then(currentBTCBlock => {
                this.setState({ currentBTCBlock: currentBTCBlock.height });
            }).catch(error => {
                console.log(error);
                this.setState({ currentBTCBlock: 'Unknown' });
            });
    }

    // Retrieves the list of items from the Express app
    getStackingStats = () => {
        fetch(makeEndpoint('/stacking'))
            .then(res => res.json())
            .then(cycles => this.setState({ cycles: cycles }))
            .then(a=> this.setState({ currentCycle: this.getCurrentCycle(this.state.cycles) }))
    }


    createCycleRow(cycleNumber, cycle) {
        const token = city.toUpperCase();
        let currentCycle=this.state.currentCycle;
        const status = this.getCycleStatus(cycleNumber, currentCycle);
        if (cycleNumber > parseInt(currentCycle) + 1) {
            return null;
        }
        const blockType=cycle.isBitCoinCycle
                        ? 'Bitcoin Block'
                        : 'Stacks Block';
        const cycleRow = (
            <div className={"cyclerow " + status} id={"row-" + cycleNumber} data-block={"" + cycleNumber} key={cycleNumber}>
                <div className="cycle-column">
                    <div className="value">{cycleNumber}</div>
                    <div className="label">CYCLE</div>
                </div>
                <div className="committed-column">
                    <div className="value">{(cycle.totalAmtStacked/1e6).toLocaleString('us', {maximumFractionDigits: 0})}</div>
                    <div className="label">{token} STACKED</div>
                </div>
                <div className="stxmia-column">
                    <div className="value">{(cycle.rewardPerToken).toLocaleString('us', {minimumFractionDigits: 8})}</div>
                    <div className="label">STX/{token}</div>
                </div>
                <div className="start-block-column">
                    <div className="value">{cycle.firstBlock}</div>
                    <div className="label">START BLOCK ({blockType})</div>
                </div>
                <div className="miners-column">
                    <div className="value">{(cycle.rewardAmtStx/1e6).toLocaleString('us', {maximumFractionDigits: 0})}</div>
                    <div className="label">STX TO STACKERS</div>
                </div>
                <div className="percent-complete-column">
                    <div className="value">{(cycle.pctComplete).toFixed(2)}%</div>
                    <div className="label">PERCENT COMPLETE</div>
                </div>
            </div>
        )
        return cycleRow;
    }

    getCurrentCycle(cycles) {
        let currentCycle=Object.values(cycles).filter((v)=>v.isCurrentCycle)[0].rewardCycle;
        return currentCycle;
    }

    getCycleStatus(cycleNumber, currentCycle) {
        if (parseInt(cycleNumber) === parseInt(currentCycle)) {
            return 'pulse';
        } else if (parseInt(cycleNumber) > parseInt(currentCycle)) {
            return 'future';
        } else {
            return 'past';
        }
    }

    render() {
        let cycles;
        let header;
        if (!this.state.cycles || !this.state.currentCycle) {
            cycles = <div>Loading...</div>
        } else {
            cycles = (
                <div id="blockWrapper">
                    <div className="cycle-list">
                    {
                        Object.keys(this.state.cycles).reverse().map((cycle) => {
                            return this.createCycleRow(cycle, this.state.cycles[cycle]);
                        }).filter((x) => x ? true : false)
                    }
                    </div>
                </div>
            )
        }
        if (this.state.currentBlock && this.state.currentBTCBlock) {
            header=<Header page="Stacking" currentBlock={this.state.currentBlock} currentBTCBlock={this.state.currentBTCBlock}  />
        }

        return (
            <div>
                <div className="wrapper"></div>
                <div>{header}</div>
                <div>
                {cycles}
                </div>
            </div>
        )
    }
}

export default Stacking;
