import React, { Component } from 'react';

class HistoryStats extends Component {
    constructor(props) {
        super(props);
        this.miner=props.miner;
        this.bnsMiner=props.bnsMiner;
        this.minerSummary=props.minerSummary;
    }

    createStatisticsBlocks(stats,bnsMiner) {
        const miner= (
            <div className="mining-address">
                {bnsMiner}
            </div>)
        const latestBlockMined = (
            <div className="statisticsBlock" key="latestBlockMined">
                <div className="statValue">{parseInt(stats.lastBlockMined).toLocaleString('us')}</div>
                <div className="statName">Last Blk Mined</div>
            </div>
        )				
        const totalBlocksMined = (
            <div className="statisticsBlock" key="totalBlocksMined">
                <div className="statValue">{stats.totalBlocksMined.toLocaleString('us')}</div>
                <div className="statName">Blocks Mined</div>
            </div>
        )
        const blocksWon = (
            <div className="statisticsBlock" key="blocksWon">
                <div className="statValue">{stats.blocksWon.toLocaleString('us')}</div>
                <div className="statName">Blocks Won</div>
            </div>
        )
        const stxCommitted = (
            <div className="statisticsBlock" key="stxCommitted">
                <div className="statValue">{(stats.stxCommitted/1e6).toLocaleString('us', {maximumFractionDigits: 2})}</div>
                <div className="statName">STX Committed</div>
            </div>
        )
        const coinsWon = (
            <div className="statisticsBlock" key="coinsWon">
                <div className="statValue">{(stats.coinsWon / 1000000).toLocaleString('us')}</div>
                <div className="statName">City Coins Won</div>
            </div>
        )

        const statBlocks = [miner,latestBlockMined,totalBlocksMined, blocksWon, stxCommitted, coinsWon]

        return (
            <div className="mining-header">
                {statBlocks}
            </div>
        )
    }

    truncateAddress(address) {
        if (address.length > 7) {
            return address.slice(0, 4) + '..' + address.slice(-3);
        } else {
            return address;
        }
    }

    render() {
        let statsBlocks;
        if (this.miner && this.bnsMiner && this.minerSummary) {
            const statBlocks = this.createStatisticsBlocks(this.minerSummary,this.truncateAddress(this.bnsMiner));
            statsBlocks=(
                <div>
                    {statBlocks}
                </div>
                )
        }
        return (
            <div>
                {statsBlocks}
            </div>
        )
    }
}

export default HistoryStats;
