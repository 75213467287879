import React, { Component } from 'react';
class JumpShowMenu extends Component {
    constructor(props) {
        super(props);
        this.page = props.page;
        this.showWinners = () => { props.showWinners(); }
        this.showAll = () => { props.showAll(); }
        this.showUnclaimed = () => { props.showUnclaimed(); }
        this.showWon = () => { props.showWon(); }
        this.showLost = () => { props.showLost(); }
        this.jumpCurrent = () => { props.jumpCurrent(); }
        this.jumpLast = () => { props.jumpLast(); }        
    }

    createJumpShowMenu(page) {
        const showLinks= page === "History"
            ?
                (         
                    <div id="blockNavigationMenu">
                    <div id="jumpToMenu"></div>                                                       
                        <div id="showBlocksMenu">
                            <div className="show-title">SHOW</div>
                            <div className="show-option show-selected" id="show-all" onClick={this.showAll}>ALL</div>
                            <div className="show-option" id="show-unclaimed" onClick={this.showUnclaimed}>UNCLAIMED</div>
                            <div className="show-option" id="show-won" onClick={this.showWon}>WON</div>
                            <div className="show-option" id="show-lost" onClick={this.showLost}>LOST</div>
                        </div>
                    </div>
            )
            :                 (
                <div id="blockNavigationMenu">                
                    <div id="jumpToMenu">
                        <div className="jump-title">JUMP TO</div>
                        <div className="jump-option" id="jump-current" onClick={this.jumpCurrent}>CURRENT</div>
                        <div className="jump-option" id="jump-last" onClick={this.jumpLast}>LATEST</div>
                    </div>
                    <div id="blockNavigationBreak"></div>                
                    <div id="showBlocksMenu">
                        <div className="show-title">SHOW</div>
                        <div className="show-option show-selected" id="show-all" onClick={this.showAll}>ALL</div>
                        <div className="show-option" id="show-winners" onClick={this.showWinners}>WINNERS</div>
                    </div>
                </div>
            )
        return (
            <div>
                {showLinks}
            </div>
        )
    }

    render() {
        let jumpShowMenu;
        if (this.page) {
            const menuOutput = this.createJumpShowMenu(this.page);
            jumpShowMenu=(
                <div>
                    {menuOutput}
                </div>
                )
        }
        return (
            <div>
                {jumpShowMenu}
            </div>
        )
    }
}

export default JumpShowMenu;
