import React, { Component } from 'react';
import './Header.css';
import mialogo from './mia.png'
import nyclogo from './nyc.png'
import {city} from './config';
import { AppConfig, UserSession, showConnect } from '@stacks/connect';

const appConfig = new AppConfig(['store_write', 'publish_data']);
const userSession = new UserSession({ appConfig });

class Header extends Component {
    // Initialize the state
    constructor(props) {
        super(props);
        this.page = props.page;
        this.currentBlock = props.currentBlock;
        this.currentBTCBlock = props.currentBTCBlock;
        this.logo= city ==='mia'? mialogo : nyclogo;
        try {
            this.state = {
                userData: JSON.parse(localStorage.getItem('userData'))
            }
        } catch {
            this.state = {
                userData: {}
            }
        }
    }

    
    componentDidMount() {
        //this.getCurrentBTCBlock();
        this.setupLinks();
        this.highlightActive();
        this.setupLogoutButton();
    }

    componentDidUpdate() {
        this.setupLogoutButton();
        this.setupLinks();
        this.highlightActive();
        this.setupLogoutButton();        
    }

    setupLogoutButton() {
        const logoutButton = document.getElementById('logout-button');
        if (logoutButton) {
            logoutButton.onclick = () => {
                this.logout();
            }
        }
    }

    setupLinks() {
        const authButton = document.getElementById('authenticate-button');
        if (authButton) {
            authButton.onclick = () => {
                this.authenticate();
            }
        }
    }

    highlightActive() {
        switch (this.page) {
            case "Block Explorer":
                const exp = document.getElementById('block-explorer-link');
                if (exp) {
                    exp.classList.add('selected-button');
                }
                break;
            case "Statistics":
                const stats = document.getElementById('statistics-link');
                if (stats) {
                    stats.classList.add('selected-button');
                }                
                break;
            case "Stacking":
                const stack = document.getElementById('stacking-link');
                if (stack) {
                    stack.classList.add('selected-button');
                }                
                break;
            case "History":
                const history = document.getElementById('history-link');
                if (history) {
                    history.classList.add('selected-button');
                }
                break;
            default:
                break;                
        }
    }

    authenticate() {
        showConnect({
            appDetails: {
                name: 'MIAMining.com',
                icon: window.location.origin + this.logo,
            },
            redirectTo: '/',
            onFinish: () => {
                let userData = userSession.loadUserData();
                this.setState({ userData: userData });
                localStorage.setItem('userData', JSON.stringify(userData));
            },
            userSession: userSession,
        });
    }

    logout() {
        localStorage.clear();
        window.location.href='/';
    }

    render() {
        const userData = this.state.userData;

        let loginButton;
        if (userData && userData.profile) {
            loginButton = (
                <a href='/history'>
                    <div className="normal-button" id="history-link">
                    History
                    </div>
                </a>
            )
        } else {
            loginButton = ( 
                <div className="action-button" id="authenticate-button">
                Login
                </div>
            )
        }

        let logoutButton;
        if (userData && userData.profile) {
            logoutButton = (
                <div className="normal-button" id="logout-button">
                Logout
                </div>
            )
        }

        let outputHeader;
        if (this.currentBlock && this.currentBTCBlock) {
            outputHeader=(
            <div>
                <div id="header">
                    <div>
                        <a href="/"><img id="mia-header-logo" src={this.logo} alt=""></img></a>
                    </div>
                    <div className="statisticsBlock" key="stxBlock">
                        <div className="statValue">{this.currentBlock.toLocaleString('us')}</div>
                        <div className="statName">STX Block</div>
                    </div>
                    <div className="statisticsBlock" key="btcBlock">
                        <div className="statValue">{this.currentBTCBlock}</div>
                        <div className="statName">BTC Block</div>
                    </div>                    
                    <div> <a href="/"> <div className="normal-button" id="block-explorer-link">Explorer </div> </a> </div>
                    <div> <a href="/stack"> <div className="normal-button" id="stacking-link"> Stacking </div></a> </div>
                    <div> <a href="/statistics"> <div className="normal-button" id="statistics-link"> Stats </div></a> </div>
                    <div> {loginButton} </div>
                    <div>{logoutButton}</div>
                </div>
            </div>
            );
        }

        return (
            <div>{outputHeader}</div>
        )
    }
}

export default Header;
