import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import List from './List';
import History from './History';
import Statistics from './Statistics';
import Stacking from './Stacking';

class App extends Component {
    render() {
        const App = () => (
            <div>
            <Switch>
            <Route exact path='/' component={List}/>
            <Route path='/explorer' component={List}/>
            <Route path='/winningbids' render={() => (
                <List alternativeUI={true} />
            )}/>
            <Route path='/history/:address' component={History}/>
            <Route path='/history' component={History}/>            
            <Route path='/statistics' component={Statistics}/>
            <Route path='/stack' component={Stacking}/>
            <Route path='*' component={List}/>
            </Switch>
            </div>
        )
        return (
            <Switch>
            <App/>
            </Switch>
        );
    }
}

export default App;
