import React from 'react';
import Chart from 'react-apexcharts'

class MiningChart extends React.Component {
    constructor(props) {
        super(props);
        this.recentMiningCommitments = props.recentMiningCommitments;
        this.state = {};
    }

    componentDidMount() {
        this.setState({recentMiningCommitments: this.recentMiningCommitments});
        this.createChart(this.recentMiningCommitments);
    }

    createChart = (recentMiningCommitments) => {
        var totalCommitted = [];
        var blockNumbers = [];

        recentMiningCommitments.forEach(function(item) {
            totalCommitted.push(item.totalCommitted);
            blockNumbers.push('Block #' + item.blockHeight);
        });

        let chartData = {
            series: [{
                name: "STX Committed",
                data: totalCommitted.slice(-recentMiningCommitments.length),
            }],
            options: this.chartOptions(blockNumbers.slice(-recentMiningCommitments.length)),
        }

        this.setState({ chart: chartData });
    }

    render() {
        let chart;
        if (this.state.chart) {
            chart = (
                <Chart className="chart" options={this.state.chart.options} series={this.state.chart.series} type="area" height={350} />
            )
        } else {
            chart = (
                <div>Loading...</div>
            )
        }

        return (
            <div id="chart">
            {chart}
            </div>
        );
    }

    chartOptions(blockNumbers) {
        return {
            chart: {
                type: 'area',
                stacked: false,
                height: 350,
                fontFamily: '"SF Mono", "Menlo Regular", "CMU Typewriter Text", "Courier New", monospace',
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                },
                toolbar: {
                    autoSelected: 'zoom'
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                text: 'STX Committed per Block, Last 250 Blocks',
                align: 'left'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: {
                labels: {
                    formatter: function (val) {
                        return (val).toFixed(2);
                    },
                },
                title: {
                    text: 'Block Commitment'
                },
            },
            xaxis: {
                categories: blockNumbers,
            },
            tooltip: {
                shared: false,
                y: {
                    formatter: function (val) {
                        return (val).toFixed(2)
                    }
                }
            },
            annotations: {
                xaxis: [
                    {
                        x: 'Block #',
                        borderColor: '#775DD0',
                        label: {
                            style: {
                                color: '#aaa',
                            },
                            text: 'Current Block'
                        }
                    }
                ]
            }
        }
    }
}

export default MiningChart;
