import $ from 'jquery';
import './MiningHistory.css';
import React, { Component } from 'react';
import Header from './Header.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import makeEndpoint from './Endpoints.js';
import JumpShowMenu from './JumpShowMenu';
import HistoryStats from './HistoryStats';

class History extends Component {
    // Initialize the state
    constructor(props) {
        super(props);
        //These methods have the List component context bound so when they get called from child components they work correctly
         this.showUnclaimed=this.showUnclaimed.bind(this);
         this.showAll=this.showAll.bind(this);
         this.showWon=this.showWon.bind(this);
         this.showLost=this.showLost.bind(this);                
         this.jumpCurrent=this.jumpCurrent.bind(this);
         this.jumpLast=this.jumpLast.bind(this);        

         try {
            this.state = {
                userData: JSON.parse(localStorage.getItem('userData')),
                blocks: {},                
                address: this.props.match.params.address
            };
         } catch {
             this.state = { 
                 blocks: {},                
                 address: this.props.match.params.address
             };
         }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.getCurrentBlock();
        this.getCurrentBTCBlock();        
        this.getMinerSummary();
        this.getBNS();
    }

    componentDidUpdate() {
        this.setupJQuery();
        if (!this.state.jumped) {
            let success = this.jumpCurrent();
            if (success) {
                this.setState({jumped:true});
            }
        }
    }

    getSTXAddress() {
        if (this.state.address) {
            if (this.state.address.includes('.btc')) {
                if (this.state.bns) {
                    const index = Object.values(this.state.bns).indexOf(
						this.state.address.replace('.btc', '')
					);
                    return Object.keys(this.state.bns)[index];
                } else {
                    return this.state.address;
                }
            } else {
                return this.state.address;
            }
        } else if (!this.state.userData) {
            return '';
        } else {
            return this.state.userData.profile.stxAddress.mainnet;
        }
    }

    getCurrentBlock = () => {
        fetch(makeEndpoint('/blocks/current_block'))
            .then(res => res.json())
            .then(currentBlock => {
                this.setState({ currentBlock: currentBlock.currentBlock });
                this.getBlocksNext(); 
            })
    }

    getCurrentBTCBlock = () => {
        fetch('https://api.blockcypher.com/v1/btc/main')
            .then(function(response) {

                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();               
            }).then(currentBTCBlock => {
                this.setState({ currentBTCBlock: currentBTCBlock.height });
            }).catch(error => {
                console.log(error);
                this.setState({ currentBTCBlock: 'Unknown' });
            });
    }

    getMinerSummary = () => {
        fetch(makeEndpoint(`/minersummary?miner=${this.getSTXAddress()}`))
            .then(res => res.json())
            .then(minerSummary => {
                this.setState({ minerSummary: minerSummary });
            })
    }

    getBNS = () => {
        fetch(makeEndpoint('/bns'))
            .then(res => res.json())
            .then(bns => this.setState({bns: bns}));
    }

    
    getBlocksNext = () => {
        if (!this.state.currentBlock) {
            return;
        }
        const address = this.getSTXAddress();
        if (!address || address ==='') {
            return;
        }

        const numberOfRecords=500;
        const miner=this.getSTXAddress();
        let currentFilter=this.state.currentFilter;
        if (!currentFilter) {
            currentFilter='all';
            this.setState({currentFilter:currentFilter});
        }

        const endBlock = this.state.earliestFetchedBlock
                ? this.state.earliestFetchedBlock-1
                : this.state.currentBlock + 100

        console.log(`FETCHING BLOCKS: End:${endBlock} | Miner: ${miner} | Filter: ${currentFilter} | BlockCount: ${numberOfRecords}`);
        //let blocksEndPoint=makeEndpoint(`/v3blocks?start=96782&stop=99500&miner=${'SP1FJ0MY8M18KZF43E85WJN48SDXYS1EC4BCQW02S'}`);
        let url=`/v3blocks?stop=${endBlock}&miner=${miner}&filter=${currentFilter}&blockcount=${numberOfRecords}`;
        let blocksEndPoint=makeEndpoint(url);

        fetch(blocksEndPoint)
            .then(res => res.json())
            .then(blocks => {
                const blockArraySorted=Object.keys(blocks).sort((a, b) => (parseInt(a) > parseInt(b)) ? 1 : -1);
                const numReturned=blockArraySorted.length;
                const earliestBlockReturned=blockArraySorted[0];
                console.log(`NUM FETCHED: ${numReturned} FIRST BLOCK: ${earliestBlockReturned} FINAL BLOCK: ${blockArraySorted[numReturned-1]}`)
                this.setState({ 
                    blocks: Object.assign({}, this.state.blocks, blocks),
                    earliestFetchedBlock: earliestBlockReturned,
                })
                const finishedLoading=numReturned<numberOfRecords;
                this.setState({finishedLoading:finishedLoading});
            })
    }

    setupJQuery() {
        $('.blockrow')
            .off('click')
            .click((event) => {
            this.expandRow(event);
        });
    }

    jumpCurrent(fast) {
        const currentBlock = this.state.currentBlock;
        if (!currentBlock) {
            return;
        }

        const jumpBlock = currentBlock + 3;
        const element = document.getElementById('row-' + jumpBlock);
        if (!element) {
            return;
        }
        const options = {
            top: element.offsetTop
        }

        if (fast === undefined || !fast) {
            options.behavior = 'smooth';
        }
        const scrollView = document.getElementsByClassName('block-list')[0];
        scrollView.scrollTo(options);
        return true;
    }

    jumpLast() {
        const scrollView = document.getElementsByClassName('block-list')[0];
        scrollView.scrollTo({top: 0, behavior: 'smooth'});
    }

    showAll() {
        const showAllText = document.getElementById('show-all');
        showAllText.classList.add('show-selected')
        this.toggleShowLinks(showAllText,1.0)        ;

        this.jumpCurrent(true);
    }

    showUnclaimed() {
        const showUnclaimedText = document.getElementById('show-unclaimed');
        showUnclaimedText.classList.add('show-selected')
        this.toggleShowLinks(showUnclaimedText,0.5,'unclaimed');

        this.jumpLast();
    }

    showWon() {
        const showWonText = document.getElementById('show-won');
        showWonText.classList.add('show-selected')
        this.toggleShowLinks(showWonText,0.5,'won')

        this.jumpLast();
    }

    showLost() {
        const showLostText = document.getElementById('show-lost');
        showLostText.classList.add('show-selected')
        this.toggleShowLinks(showLostText,0.5,'lost')

        this.jumpLast();
    }

    toggleShowLinks(targetElement,jumpLinkOpacity=0.5,filter='all') {
        const currentFilter=this.state.currentFilter;
        if (filter === currentFilter) {
            return;
        }
        
        const showAllText = document.getElementById('show-all');
        const showUnclaimedText = document.getElementById('show-unclaimed');
        const showWonText = document.getElementById('show-won');
        const showLostText = document.getElementById('show-lost');        
        if (targetElement !== showAllText) showAllText.classList.remove('show-selected')
        if (targetElement !== showUnclaimedText) showUnclaimedText.classList.remove('show-selected')        
        if (targetElement !== showWonText) showWonText.classList.remove('show-selected')        
        if (targetElement !== showLostText) showLostText.classList.remove('show-selected')                        

        this.setState({
            currentFilter:filter,
            blocks: {},
            earliestFetchedBlock: undefined}, 
                () => {this.getBlocksNext();
          }); 

        const jumpElements = document.getElementsByClassName('jump-option');
        for (var jumpElement of jumpElements) {
            jumpElement.style.opacity = jumpLinkOpacity;
        }

    }

    expandRow(event) {
        var element = event.target;

        while (!element.dataset.block) {
            if (element.className === 'drill') return;
            element = element.parentElement;
        }

        const block = element.dataset.block;
        const drill = $('#drill-' + block);
        const chevron = $('#chevron-' + block);
        chevron.toggleClass('gg-chevron-up');
        drill.slideToggle('slow');
    }

    truncateAddress(address) {
        if (address.length > 7) {
            return address.slice(0, 4) + '..' + address.slice(-3);
        } else {
            return address;
        }
    }

    getWinnerString(blockNumber, currentBlock, winner) {
        var winnerTruncated;
        if (winner) {
            winnerTruncated = this.truncateAddress(winner);
        } 
        if (blockNumber >= currentBlock) {
            winnerTruncated = winnerTruncated || 'PENDING';
        } else if (blockNumber > currentBlock - 100) {
            winnerTruncated = winnerTruncated || 'UNRELEASED';
        } else {
            winnerTruncated = winnerTruncated || 'UNCLAIMED';
        }
        return winnerTruncated;
    }

    getBlockStatus(blockInfo, blockNumber, currentBlock, winner) {
        let currentUser = this.getSTXAddress();        
        let winnerString;
        if (!winner) {
            winnerString = 'undetermined';
        } else {
            if (winner !== currentUser) {
                winnerString = 'loser';
            } else if (winner === currentUser && blockInfo.claimed) {
                winnerString = 'winner';
            } else {
                winnerString = 'winner pulse';
            }
        }

        if (blockNumber === currentBlock) {
            return 'pulse';
        } else if (blockNumber > currentBlock) {
            return 'future';
        } else if (blockNumber > currentBlock - 100) {
            return winnerString;
        } else if (blockNumber < currentBlock) {
            return winnerString;
        }
    }

    getWinningBid(blockData) {
        let winningBid;
        const winner=blockData['winner'];
        if (winner && blockData.miners !==undefined && blockData.miners[winner]) {
            winningBid = parseFloat((blockData.miners[winner]/1e6).toFixed(2)) + ' STX';
        } else {
            winningBid = '--';
        }
        return winningBid;
    }

    getReward(blockData) {
        let reward = parseInt(blockData['reward'])/1000000;
        if (isNaN(reward)) {
            reward='';
        }
        return reward;
    }

    getBnsForAddress(address,bnsArray) {
		let returnAddress = address;        
        if (bnsArray && address && bnsArray[address]) {
			returnAddress = bnsArray[address];
        }
        return returnAddress;
    }

    getOrderedMiners(blockData) {
        const arr = [];
        for (var miner in blockData['miners']) {
            arr.push({
                'miner': miner,
                'commitment': blockData['miners'][miner],
            });
        }
        return arr.sort((a, b) => (a.commitment < b.commitment) ? 1 : -1);
    }

    generateMinerRows(blockData, winner, totalCommitted) {
        const orderedMiners = this.getOrderedMiners(blockData);
        return orderedMiners.map((minerData) => {
            const miner = minerData.miner;
            const commitment = minerData.commitment;
            const bns=this.getBnsForAddress(miner,this.state.bns);;
            return(
                <div className={"minerRow " + ((winner === miner) ? "winner-row" : "")} key={miner}>
                <div className="miner-address miner-address-truncated">
                    <a className="miner-link" href={"https://explorer.stacks.co/address/" + miner + "?chain=mainnet"} target="_blank" rel="noopener noreferrer">
                        {this.truncateAddress(bns)}
                    </a>
                </div>
                <div className="miner-address miner-address-full">
                    <a className="miner-link" href={"https://explorer.stacks.co/address/" + miner + "?chain=mainnet"} target="_blank" rel="noopener noreferrer">
                        {bns}
                    </a>
                </div>
                <div><b>{(commitment/1e6).toString()} STX</b></div>
                <div>{(commitment*100/totalCommitted).toPrecision(2)}%</div>
                </div>
            )
        })
    }

    generateBlockRow(blockNumber, blockData) {
        const totalCommitted =  blockData['minerCommitment'];
        const myCommitment = blockData.miners[this.getSTXAddress()];
        const pctCommitted = parseFloat(myCommitment)/parseFloat(totalCommitted);
        const winner = blockData['winner'];        
        const status = this.getBlockStatus(blockData, blockNumber, this.state.currentBlock, winner);
        const winnerString = this.getWinnerString(blockNumber, this.state.currentBlock, winner);

        const blockRow = (
            <div className={"blockrow " + status} id={"row-" + blockNumber} data-block={"" + blockNumber} key={blockNumber}>
                <div className="block-column">
                    <div className="value">{blockNumber}</div>
                    <div className="label">BLOCK</div>
                </div>
                <div className="committed-column">
                    <div className="value">{(myCommitment/1e6).toFixed(2)} STX</div>
                    <div className="label">YOUR COMMITMENT</div>
                </div>
                <div className="miners-column">
                    <div className="value">{(totalCommitted/1e6).toFixed(2)} STX</div>
                    <div className="label">ALL MINERS COMMITMENT</div>
                </div>
                <div className="reward-column">
                    <div className="value">{pctCommitted.toFixed(2)}%</div>
                    <div className="label">CHANCE OF WIN</div>
                </div>
                <div className="winner-column">
                    <div className="value">{winnerString}</div>
                    <div className="label">WINNER</div>
                </div>
                <div className="chevron-row">
                <div className="gg-chevron-down" id={"chevron-" + blockNumber} data-block={blockNumber}></div>
                </div>
                <div className="drill" id={"drill-" + blockNumber}>
                    {this.generateMinerRows(blockData, winner, totalCommitted)}
                </div>
            </div>
        )
        return blockRow;
    }

    render() {
        let blocksContent;
        let header;
        let minerStats;
        if (!this.state.userData && !this.state.address) {
            blocksContent = <div>Not logged in.</div>
        } else if (!this.state.blocks || !this.state.currentBlock || !this.state.bns || !this.state.minerSummary) {
            blocksContent = <div>Loading...</div>
        } else {
            var { blocks } = this.state;                    
            const blockNumbers = Object.keys(blocks).sort((a, b) => (parseInt(a) < parseInt(b)) ? 1 : -1);
            if (blockNumbers.length) {
                blocksContent = (
                    <div>
                        <JumpShowMenu page="History"
                            jumpCurrent={this.jumpCurrent} 
                            jumpLast={this.jumpLast} 
                            showAll={this.showAll} 
                            showUnclaimed={this.showUnclaimed}
                            showWon={this.showWon}
                            showLost={this.showLost}>
                        </JumpShowMenu>
                        <div id="block-list" className="block-list">
                            <InfiniteScroll 
                                dataLength={blockNumbers.length}
                                next={this.getBlocksNext}
                                hasMore={!this.state.finishedLoading}
                                loader={<div>Loading...</div>}
                                scrollableTarget="block-list"
                            >
                            {
                                blockNumbers.map((blockNumber) => {
                                    const blockData = blocks[blockNumber];
                                    return this.generateBlockRow(blockNumber, blockData);
                                })
                            }
                            </InfiniteScroll>
                        </div>
                    </div>
                )
            }
            const bnsMiner=this.getBnsForAddress(this.getSTXAddress(),this.state.bns);            
            minerStats=<HistoryStats miner={this.getSTXAddress()} bnsMiner={bnsMiner} minerSummary={this.state.minerSummary} />                                    
        }
        if (this.state.currentBlock && this.state.currentBTCBlock) {
            header=<Header page="History" currentBlock={this.state.currentBlock} currentBTCBlock={this.state.currentBTCBlock}  />
        }
        return (
            <div className="App">
                <div className="wrapper"></div>
                <div>{header}</div>
                <div>{minerStats}</div>                
                <div id="blockWrapper">
                    <div>{blocksContent}</div>
                </div>
            </div>
        );
    }
}

export default History;
