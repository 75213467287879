const makeEndpoint = (uri) => {
    const env = process.env.NODE_ENV;
    if (env === 'development') {
        //return 'https://localhost:8443' + uri;
        return 'http://localhost:8080' + uri;
        //return 'https://mining.nyc' + uri;
        //return 'https://miamining.com' + uri;
    } else {
        return uri
    }
}

export default makeEndpoint;
