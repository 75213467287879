import React, { Component } from 'react';
import Header from './Header.js';
import MiningChart from './MiningChart';
import WinnersChart from './WinnersChart';
import makeEndpoint from './Endpoints.js';
import './Statistics.css';

class Statistics extends Component {
    // Initialize the state
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.getCurrentBlock();
        this.getCurrentBTCBlock();        
        this.getData();
    }

    getCurrentBlock = () => {
        fetch(makeEndpoint('/blocks/current_block'))
            .then(res => res.json())
            .then(currentBlock => {
                this.setState({ currentBlock: currentBlock.currentBlock });
            })
    }    

    getCurrentBTCBlock = () => {
        fetch('https://api.blockcypher.com/v1/btc/main')
            .then(function(response) {

                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();               
            }).then(currentBTCBlock => {
                this.setState({ currentBTCBlock: currentBTCBlock.height });
            }).catch(error => {
                console.log(error);
                this.setState({ currentBTCBlock: 'Unknown' });
            });
    }

    getData = () => {
        fetch(makeEndpoint('/summary'))
            .then(res => res.json())
            .then(summary => this.setState({summary: summary}))            
    }

    createSummaryBlocks(summary) {
        const blocksMined = (
            <div className="statisticsBlock" key="blocksMined">
                <div className="statValue">{parseInt(summary.blocksMined).toLocaleString('us')}</div>
                <div className="statName">Blocks Mined</div>
            </div>
        )
        const currentSupply = (
            <div className="statisticsBlock" key="currentSupply">
                <div className="statValue">{parseInt(summary.currentSupply).toLocaleString('us')} {summary.tokenSymbol}</div>
                <div className="statName">Current Supply</div>
            </div>
        )
        const totalStxCommitted = (
            <div className="statisticsBlock" key="totalStxCommitted">
                <div className="statValue">{parseInt(summary.totalStxCommitted).toLocaleString('us')} STX</div>
                <div className="statName">Total Committed</div>
            </div>
        )
        const cityWalletStxBalance = (
            <div className="statisticsBlock" key="cityWalletStxBalance">
                <div className="statValue">{parseInt(summary.cityWalletStxBalance).toLocaleString('us')} STX</div>
                <div className="statName">City Wallet STX</div>
            </div>
        )
        const stackedCCBalance = (
            <div className="statisticsBlock" key="stackedCCBalance">
                <div className="statValue">{parseInt(summary.stackedCCBalance).toLocaleString('us')} {summary.tokenSymbol}</div>
                <div className="statName">Current Cycle Stacked {summary.tokenSymbol}</div>
            </div>
        )                
        const summaryBlocks = [blocksMined, currentSupply, totalStxCommitted, cityWalletStxBalance, stackedCCBalance]

        return (
            <div className="statisticsBlocks">
                {summaryBlocks}
            </div>
        )
    }

    render() {
        let charts;
        let summaryBlocks;
        let header;
        if (this.state.summary) {
            summaryBlocks = this.createSummaryBlocks(this.state.summary);
            charts = (
                <div>
                {   <MiningChart recentMiningCommitments={this.state.summary.recentMiningCommitments} /> }
                    <WinnersChart topWinners={this.state.summary.topWinners} />
                </div>
            )
        } else {
            charts = (
                <div>Loading...</div>
            )
            summaryBlocks = (<div></div>)
        }

        if (this.state.currentBlock && this.state.currentBTCBlock) {
            header=<Header page="Statistics" currentBlock={this.state.currentBlock} currentBTCBlock={this.state.currentBTCBlock}  />
        }

        return (
            <div>
                <div className="wrapper"></div>
                <div>{header}</div>
                <div className="blockWrapper">{summaryBlocks}</div>
                <div id="chartWrapper">
                    {charts}
                </div>
            </div>
        )
    }
}

export default Statistics;

