import $ from 'jquery';
import React, { Component } from 'react';
import Header from './Header.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import makeEndpoint from './Endpoints.js';
import JumpShowMenu from './JumpShowMenu';

class List extends Component {
    // Initialize the state
    constructor(props) {
        super(props);
        //These methods have the List component context bound so when they get called from child components they work correctly
        this.showWinners=this.showWinners.bind(this);
        this.showAll=this.showAll.bind(this);
        this.jumpCurrent=this.jumpCurrent.bind(this);
        this.jumpLast=this.jumpLast.bind(this);        

        this.state = {
            blocks: {}
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.getCurrentBlock();
        this.getCurrentBTCBlock();
        this.getBNS();
    }

    componentDidUpdate() {
        this.setupJQuery();
        if (!this.state.jumped) {
            let success = this.jumpCurrent();
            if (success) {
                this.setState({jumped:true});
            }
        }
    }

    getCurrentBlock = () => {
        fetch(makeEndpoint('/blocks/current_block'))
            .then(res => res.json())
            .then(currentBlock => {
                this.setState({ currentBlock: currentBlock.currentBlock });
                this.getBlocksNext(); 
            })
    }

    getCurrentBTCBlock = () => {
        fetch('https://api.blockcypher.com/v1/btc/main')
            .then(function(response) {

                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.json();               
            }).then(currentBTCBlock => {
                this.setState({ currentBTCBlock: currentBTCBlock.height });
            }).catch(error => {
                console.log(error);
                this.setState({ currentBTCBlock: 'Unknown' });
            });
    }

    getBNS = () => {
        fetch(makeEndpoint('/bns'))
            .then(res => res.json())
            .then(bns => this.setState({bns: bns}));
    }

    getBlocksNext = () => {

        if (!this.state.currentBlock) {
            return;
        }

        let lastFetchedBlock
        if (this.state.lastFetchedBlock) {
            lastFetchedBlock = this.state.lastFetchedBlock;
        } else {

            lastFetchedBlock = this.state.currentBlock + 100;
        }

        let startBlock = lastFetchedBlock - 500;
        let endBlock = lastFetchedBlock;

        console.log('Fetching from ' + startBlock + ' to ' + endBlock);

        fetch(makeEndpoint('/v3blocks?start=' + startBlock + '&stop=' + endBlock))
            .then(res => res.json())
            .then(blocks => {
                this.setState({ 
                    blocks: Object.assign({}, this.state.blocks, blocks),
                    lastFetchedBlock: startBlock,
                })
                if (startBlock < 24497) {
                    this.setState({finishedLoading:true});
                }
            })
    }

    setupJQuery() {
        $('.blockrow')
            .off('click')
            .click((event) => {
            this.expandRow(event);
        });
    }


    jumpCurrent(fast) {
        const currentBlock = this.state.currentBlock;
        if (!currentBlock) {
            return;
        }

        const jumpBlock = currentBlock + 3;
        const element = document.getElementById('row-' + jumpBlock);
        if (!element) {
            return;
        }
        const options = {
            top: element.offsetTop
        }

        if (fast === undefined || !fast) {
            options.behavior = 'smooth';
        }
        const scrollView = document.getElementsByClassName('block-list')[0];
        scrollView.scrollTo(options);
        return true;
    }

    jumpLast() {
        const scrollView = document.getElementsByClassName('block-list')[0];
        scrollView.scrollTo({top: 0, behavior: 'smooth'});
    }

    showAll() {
        const elements = document.getElementsByClassName('blockrow');
        for (var element of elements) {
            element.style.display = '';
        }

        const showAllText = document.getElementById('show-all');
        showAllText.classList.add('show-selected')
        this.toggleShowLinks(showAllText,1.0)        ;

        this.jumpCurrent(true);
    }

    showWinners() {
        this.showAll();
        const elements = document.getElementsByClassName('blockrow');
        for (var element of elements) {
            if (
                    element.dataset.winner === 'PENDING' 
                || element.dataset.winner === 'UNCLAIMED'
                || element.dataset.winner === 'UNRELEASED'
                ) {
                element.style.display = 'none';
            }
        }

        const showWinnersText = document.getElementById('show-winners');
        showWinnersText.classList.add('show-selected')
        this.toggleShowLinks(showWinnersText)
        this.jumpLast();
    }

    toggleShowLinks(targetElement,jumpLinkOpacity=0.5) {
        const showAllText = document.getElementById('show-all');
        const showWinnersText = document.getElementById('show-winners');
        if (targetElement !== showAllText) showAllText.classList.remove('show-selected')
        if (targetElement !== showWinnersText) showWinnersText.classList.remove('show-selected')        

        const jumpElements = document.getElementsByClassName('jump-option');
        for (var jumpElement of jumpElements) {
            jumpElement.style.opacity = jumpLinkOpacity;
        }

    }

    expandRow(event) {
        var element = event.target;

        while (!element.dataset.block) {
            if (element.className === 'drill') return;
            element = element.parentElement;
        }

        const block = element.dataset.block;
        const drill = $('#drill-' + block);
        const chevron = $('#chevron-' + block);
        chevron.toggleClass('gg-chevron-up');
        drill.slideToggle('slow');
    }

    truncateAddress(address) {
        if (address.length > 7) {
            return address.slice(0, 4) + '..' + address.slice(-3);
        } else {
            return address;
        }
    }

    getWinnerString(blockNumber, currentBlock, winner) {
        var winnerTruncated;
        if (winner) {
            winnerTruncated = this.truncateAddress(winner);
        } 
        if (blockNumber >= currentBlock) {
            winnerTruncated = winnerTruncated || 'PENDING';
        } else if (blockNumber > currentBlock - 100) {
            winnerTruncated = winnerTruncated || 'UNRELEASED';
        } else {
            winnerTruncated = winnerTruncated || 'UNCLAIMED';
        }
        return winnerTruncated;
    }

    getBlockStatus(blockNumber, currentBlock) {
        if (blockNumber === currentBlock) {
            return 'pulse'
        } else if (blockNumber > currentBlock) {
            return 'future'
        } else if (blockNumber > currentBlock - 100) {
            return 'past'
        } else if (blockNumber < currentBlock) {
            return 'past'
        }
    }

    getWinningBid(blockData) {
        let winningBid;
        const winner=blockData['winner'];
        if (winner && blockData.miners !==undefined && blockData.miners[winner]) {
            winningBid = parseFloat((blockData.miners[winner]/1e6).toFixed(2)) + ' STX';
        } else {
            winningBid = '--';
        }
        return winningBid;
    }

    getReward(blockData) {
        let reward = parseInt(blockData['reward'])/1000000;
        if (isNaN(reward)) {
            reward='';
        }
        return reward;
    }

    getBnsForAddress(address,bnsArray) {
        if (bnsArray && address) {
            return bnsArray[address];
        }
        return '';
    }

    getOrderedMiners(blockData) {
        const arr = [];
        for (var miner in blockData['miners']) {
            arr.push({
                'miner': miner,
                'commitment': blockData['miners'][miner],
            });
        }
        return arr.sort((a, b) => (a.commitment < b.commitment) ? 1 : -1);
    }

    generateMinerRows(blockData, winner, totalCommitted) {
        const orderedMiners = this.getOrderedMiners(blockData);
        return orderedMiners.map((minerData) => {
            const miner = minerData.miner;
            const commitment = minerData.commitment;
            const bns=this.getBnsForAddress(miner,this.state.bns);
            let commitmentpct=commitment*100/totalCommitted;
            if (commitmentpct !== 100) {
                commitmentpct=commitmentpct.toPrecision(2);
            }

            return(
                <div className={"minerRow " + ((winner === miner) ? "winner-row" : "")} key={miner}>
                <div className="miner-address miner-address-truncated">
                    <a className="miner-link" href={"https://explorer.stacks.co/address/" + miner + "?chain=mainnet"} target="_blank" rel="noopener noreferrer">
                        {bns ? bns : this.truncateAddress(miner)}
                    </a>
                </div>
                <div className="miner-address miner-address-full">
                    <a className="miner-link" href={"https://explorer.stacks.co/address/" + miner + "?chain=mainnet"} target="_blank" rel="noopener noreferrer">
                        {bns ? bns : miner}
                    </a>
                </div>
                <div><b>{(commitment/1e6).toString()} STX</b></div>
                {/* <div>{(commitment*100/totalCommitted).toPrecision(2)}%</div> */}
                <div>{commitmentpct}</div>
                </div>
            )
        })
    }

    generateBlockRow(blockNumber, blockData, currentBlock) {
        blockNumber = parseInt(blockNumber);
        const totalCommitted =  blockData['minerCommitment'];
        const numberOfMiners = blockData['minerCount'];
        const winner = blockData['winner'];
        const status = this.getBlockStatus(blockNumber, currentBlock);
        const reward = this.getReward(blockData)

        const winningBid=this.getWinningBid(blockData);
        const winnerTruncated = this.getWinnerString(blockNumber, currentBlock, winner);
        const winnerBNS=this.getBnsForAddress(winner,this.state.bns);
        const winnerString = winnerBNS ? this.truncateAddress(winnerBNS) : winnerTruncated;
        return(
             <div className={"blockrow " + status} id={"row-" + blockNumber} data-block={"" + blockNumber} data-winner={"" + winnerTruncated} key={blockNumber}>        
                 <div className="block-column">
                    <div className="value">{blockNumber}</div>
                    <div className="label">BLOCK</div>
                </div>
                <div className="committed-column">
                    <div className="value">{(totalCommitted/1e6).toFixed(2)} STX</div>
                    <div className="label">COMMITTED</div>
                </div>
                <div className="miners-column">
                    <div className="value">{numberOfMiners}</div>
                    <div className="label">MINERS</div>
                </div>
                <div className="reward-column">
                    <div className="value">{this.props.alternativeUI ? winningBid : reward.toLocaleString('us')}</div>
                    <div className="label">{this.props.alternativeUI ? "WINNING BID" : "REWARD"}</div>
                </div>
                <div className="winner-column">
                    <div className={"value " + (winner ? 'winner-text' : '')}>{winnerString}</div>
                    <div className="label">WINNER</div>
                </div>          
                <div className="chevron-row">
                    <div className="gg-chevron-down" id={"chevron-" + blockNumber} data-block={blockNumber}></div>
                </div>
                <div className="drill" id={"drill-" + blockNumber}>
                    {this.generateMinerRows(blockData, winner, totalCommitted)}
                </div>
            </div>
        )
    }

    render() {
        var { blocks, currentBlock } = this.state;
        const blockNumbers = Object.keys(blocks).sort((a, b) => (parseInt(a) < parseInt(b)) ? 1 : -1);
        let blocksContent;
        let header;
        if (blockNumbers.length) {
            blocksContent = (
                <div>
                    <JumpShowMenu page="List"
                        jumpCurrent={this.jumpCurrent} 
                        jumpLast={this.jumpLast} 
                        showAll={this.showAll} 
                        showWinners={this.showWinners}>
                    </JumpShowMenu>
                    <div id="block-list" className="block-list">
                        <InfiniteScroll 
                            dataLength={blockNumbers.length}
                            next={this.getBlocksNext}
                            hasMore={!this.state.finishedLoading}
                            loader={<div>Loading...</div>}
                            scrollableTarget="block-list"
                        >
                        {
                            blockNumbers.map((blockNumber) => {
                                const blockData = blocks[blockNumber];
                                return this.generateBlockRow(blockNumber, blockData, currentBlock);
                            })
                        }
                        </InfiniteScroll>
                    </div>
                </div>
        )
        } else {
            blocksContent = (
                <div>
                <h2>Loading blocks...</h2>
                </div>
            )
        }

        if (this.state.currentBlock && this.state.currentBTCBlock) {
            header=<Header page="Block Explorer" currentBlock={this.state.currentBlock} currentBTCBlock={this.state.currentBTCBlock}  />
        }
        return (
            <div className="App">
                <div className="wrapper"></div>
                <div>{header}</div>
                <div id="blockWrapper">
                    <div>{blocksContent}</div>
                </div>
            </div>
        );
    }
}

export default List;
