import React from 'react';
import Chart from 'react-apexcharts'

class WinnersChart extends React.Component {
    constructor(props) {
        super(props);
        this.topWinners = props.topWinners;
        this.state = {};
    }

    componentDidMount() {
        this.createChart(this.topWinners);
    }

    createChart = (topWinners) => {
        let chartData = {
            series: [{
                name: 'Blocks Won',
                data: topWinners.map(w => w.winCount),
            }, {
                name: 'STX Committed',
                data: topWinners.map(w => w.stxCommitted),
            }],
            options: this.chartOptions(topWinners.map(w => w.address)),
        }

        this.setState({ chart: chartData });
    }

    render() {
        let chart;
        if (this.state.chart) {
            chart = (
                <Chart className="chart" options={this.state.chart.options} series={this.state.chart.series} type="bar" height={350} />
            )
        } else {
            chart = (
                <div>Loading...</div>
            )
        }

        return (
            <div id="chart">
            {chart}
            </div>
        );
    }

    chartOptions(winnerNames) {
        return {
            chart: {
                type: 'bar',
                height: 700,
                fontFamily: '"SF Mono", "Menlo Regular", "CMU Typewriter Text", "Courier New", monospace',
            },
            title: {
                text: 'Top Miners by Blocks Won',
                align: 'center'
            },
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                }
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                shared: false,
                y: {
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            xaxis: {
                categories: winnerNames,
                labels: {
                    show: false,
                }
            },
            yaxis: [
                {
                    seriesName: 'Blocks Won',
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0);
                        },
                    },
                    title: {
                        text: 'Number of Blocks Won'
                    },
                },
                {
                    seriesName: 'STX Committed',
                    opposite: true,
                    labels: {
                        formatter: function (val) {
                            return val.toFixed(0);
                        },
                    },
                    title: {
                        text: 'STX Committed'
                    },
                }
            ],
        }
    }
}

export default WinnersChart;
